import { useEffect, useState } from "react";
import { useInterval } from "./useInterval";
import { useAuth } from "../state/auth";
import { useUpdateUserMutation } from "./useUser";

/** the ID in the Chrome Web Store for our legacy (manifest v2) chrome extension */
export const v2ExtensionId = "nhhjiffocbkpnigpfjlcgpghmjigcknp";
const EXTENSION_INSTALLED_KEY = "extensionInstalled";
const IGNORE_EXTENSION_MESSAGE = "ignoreExtensionMessage";

export default function useV2ExtensionInstalled() {
    const initialExtensionInstalled = localStorage.getItem(
        EXTENSION_INSTALLED_KEY
    );
    // if local storage is set, use as default, otherwise use null to indicate
    // we need a full query
    const [isInstalled, setInstalled] = useState<boolean | null>(
        initialExtensionInstalled === null ? null : !!initialExtensionInstalled
    );

    const { data: user } = useAuth();
    const { mutate: updateUser } = useUpdateUserMutation();

    useInterval(
        () => {
            if (localStorage.getItem(IGNORE_EXTENSION_MESSAGE)) {
                return;
            }
            if (typeof chrome !== "undefined" && chrome.runtime) {
                try {
                    chrome.runtime.sendMessage(
                        v2ExtensionId,
                        { agent: "testbox_controller", action: "check" },
                        (response) => {
                            if (!response) {
                                if (isInstalled) {
                                    console.log("Extension was disabled");
                                }
                                setInstalled(false);
                                localStorage.setItem(
                                    EXTENSION_INSTALLED_KEY,
                                    ""
                                );
                            } else if (
                                response.agent === "testbox_extension" &&
                                response.action === "installed"
                            ) {
                                if (!isInstalled) {
                                    console.log(
                                        "Extension message received for first time..."
                                    );
                                }

                                setInstalled(true);
                                localStorage.setItem(
                                    EXTENSION_INSTALLED_KEY,
                                    "1"
                                );
                            }
                        }
                    );
                } catch (e) {
                    // Most likely, we have received an exception that the
                    // other end is not receiving. We can surmise that the
                    // extension is *not* installed.
                    console.log(e);
                    setInstalled(false);
                    localStorage.setItem(EXTENSION_INSTALLED_KEY, "");
                }
            } else {
                setInstalled(false);
                localStorage.setItem(EXTENSION_INSTALLED_KEY, "");
            }
        },
        5000,
        true
    );

    useEffect(() => {
        if (isInstalled && user && !user?.extension_installed) {
            console.log("Updating extension installed");
            updateUser({ extension_installed: true });
        }
    }, [isInstalled, user]);

    const searchParams = new URLSearchParams(window.location.search);
    const bypassExtensionFeatureFlagActive =
        searchParams.get("bypassExtension") !== null ||
        sessionStorage.getItem("bypassExtension");

    useEffect(() => {
        if (bypassExtensionFeatureFlagActive)
            sessionStorage.setItem("bypassExtension", "true");
    }, [bypassExtensionFeatureFlagActive]);

    return isInstalled || bypassExtensionFeatureFlagActive;
}
