export const checkIsChrome = () => {
    const isChromium = !!window.chrome;
    const winNav = window.navigator;
    const vendorName = winNav.vendor;
    const isOpera = typeof (window as any)?.opr !== "undefined";
    const isIEedge = winNav.userAgent.indexOf("Edg") > -1;
    const isIOSChrome = winNav.userAgent.match("CriOS");

    return !!(
        isIOSChrome ||
        (isChromium && vendorName === "Google Inc." && !isOpera && !isIEedge)
    );
};
