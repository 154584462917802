import {
    baseExtensionRequestAttributes,
    ExtensionRequest,
    ExtensionResponse,
    RequestName,
} from "./NoFrameMessageTypes";

export async function noFrameSendMessage<T extends RequestName>(
    extensionId: string,
    type: T,
    message: ExtensionRequest<T>["tbx_payload"]
): Promise<ExtensionResponse<T>["tbx_result"]> {
    const payload = {
        tbx_msg_type: type,
        tbx_payload: message,
        ...baseExtensionRequestAttributes(),
    };

    return new Promise((resolve, reject) => {
        chrome.runtime.sendMessage(extensionId, payload, (response) => {
            if (!response) {
                reject(
                    `did not receive response from extension ${extensionId}`
                );

                return;
            }

            if (response.tbx_error) {
                // tbx_error will be an object with the same fields as `Error`
                reject(response.tbx_error);
            } else {
                resolve(response.tbx_result);
            }
        });
    });
}
