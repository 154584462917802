import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { LaunchDarklyContextProvider } from "./components/LaunchDarkly";
import { RecoilRoot } from "recoil";
import TestboxQueryClientProvider from "./etc/queryClient";
import { lazyRetry } from "./etc/lazyRetry";
import BasicSuspenseLoading from "components/BasicSuspenseLoading";
import PreventIncognito from "components/PreventIncognito";
import { ToasterProvider } from "v2/components/ToasterProvider";
import { NoFrameExtensionProvider } from "./components/noframe/NoFrameExtension";

const App = lazyRetry(() => import("./App"));

// Why is the NODE_ENV of staging environment "production"?  Shouldn't it be "stage" or "staging"?
if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://604be4db1bda41d9b76b4f62aafcc0a1@o541645.ingest.sentry.io/5691638",
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        environment: window.location.href.includes("app.testbox.com")
            ? "production"
            : "staging",
    });
}

ReactDOM.render(
    <React.StrictMode>
        <PreventIncognito>
            <React.Suspense fallback={<BasicSuspenseLoading />}>
                <RecoilRoot>
                    <TestboxQueryClientProvider>
                        <LaunchDarklyContextProvider>
                            <NoFrameExtensionProvider>
                                <ToasterProvider>
                                    <App />
                                </ToasterProvider>
                            </NoFrameExtensionProvider>
                        </LaunchDarklyContextProvider>
                    </TestboxQueryClientProvider>
                </RecoilRoot>
            </React.Suspense>
        </PreventIncognito>
    </React.StrictMode>,
    document.getElementById("root")
);
