// the testbox app only sends outgoing messages to the extension, there is no
// use case to control the testbox app itself from the extension. there should
// be an outgoing message type in here for each incoming message type in the
// extension.

// the extension will gracefully handle multiple versions of incoming messages
// so the app only needs to aim to support sending messages based on the latest
// schema

// note that we use an enveloping technique so there are 2 levels of versioning
// there is a version on the envelope itself which contains metadata about
// the message and there is another version on the message payload which will
// be incremented when the specific message payload changes

import { ProductTrial } from "../../data/productTrials";
import { Workspace } from "../../hooks/useWorkspace";

export type RequestName =
    | "open-product-trial-tab"
    | "logout-user"
    | "connect-to-extension";

export type BaseExtensionRequest = {
    tbx_version: 1;
    tbx_source: "tbx-app";
    tbx_target: "tbx-extension-service-worker";
};

export function baseExtensionRequestAttributes(): BaseExtensionRequest {
    return {
        tbx_version: 1,
        tbx_source: "tbx-app",
        tbx_target: "tbx-extension-service-worker",
    };
}

export type BaseExtensionResponse = {
    tbx_version: 1;
    tbx_source: "tbx-extension-service-worker";
    tbx_target: "tbx-app";
};

export type OpenProductTrialTabExtensionRequest = BaseExtensionRequest & {
    tbx_msg_type: "open-product-trial-tab";
    tbx_payload: {
        version: 1;
        trial_id: string;
        workspace_id: string;
        noframing_start_url: string;
        details: {
            product_trials: ProductTrial[];
            workspace: Workspace;
        };
    };
};

export type OpenProductTrialTabExtensionResponse = BaseExtensionResponse & {
    tbx_msg_type: "open-product-trial-tab";
    tbx_result: {
        version: 1;
        tab_id: number;
    };
};

export type LogoutUserExtensionRequest = BaseExtensionRequest & {
    tbx_msg_type: "logout-user";
    tbx_payload: {
        version: 1;
        email_address: string;
    };
};

export type LogoutUserExtensionResponse = BaseExtensionResponse & {
    tbx_msg_type: "logout-user";
    tbx_result: {
        version: 1;
        logged_out: boolean;
    };
};

export type ConnectToExtensionRequest = BaseExtensionRequest & {
    tbx_msg_type: "connect-to-extension";
    tbx_payload: {};
};

export type ConnectToExtensionResponse = {
    tbx_msg_type: "connect-to-extension";
    tbx_result: {
        version: 1;
        connection_success: boolean;
    };
};

export type ExtensionRequest<R extends RequestName> =
    R extends "open-product-trial-tab"
        ? OpenProductTrialTabExtensionRequest
        : R extends "logout-user"
        ? LogoutUserExtensionRequest
        : R extends "connect-to-extension"
        ? ConnectToExtensionRequest
        : never;

export type ExtensionResponse<R extends RequestName> =
    R extends "open-product-trial-tab"
        ? OpenProductTrialTabExtensionResponse
        : R extends "logout-user"
        ? LogoutUserExtensionResponse
        : R extends "connect-to-extension"
        ? ConnectToExtensionResponse
        : never;
