import { useCallback } from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";

export interface QuerySyncableAppState {
    walkthrough?: boolean;
    mode?: string;
    useCaseConfigId?: string;
    isFullScreen?: boolean;
    skipExitEventFullScreen?: boolean;
}

export interface AppState extends QuerySyncableAppState {
    currentProductSlug?: string;
    currentLeadId?: string;
    notesPopoverOpen: boolean;
    teamInvitePopoverOpen: boolean;
    workspaceInvitePopoverOpen: boolean;
    useCaseDrawerOpen?: boolean;
    chromePopoverOpen: boolean;
    isCapture: boolean;
    workspacePopoverOpen: boolean;
    demoWorkspaceId?: string;
    pedalPanelsRefs: Record<string, any>;
    isPreviewingSandbox?: boolean;
}

const defaultState = {
    currentProductSlug: undefined,
    currentLeadId: undefined,
    notesPopoverOpen: false,
    teamInvitePopoverOpen: false,
    workspaceInvitePopoverOpen: false,
    useCaseDrawerOpen: true,
    chromePopoverOpen: false,
    workspacePopoverOpen: false,
    walkthrough: undefined,
    mode: undefined,
    isCapture: false,
    isFullScreen: undefined,
    skipExitEventFullScreen: undefined,
    demoWorkspaceId: undefined,
    pedalPanelsRefs: {},
    isPreviewingSandbox: false,
};

const appState = atom<AppState>({
    key: "app",
    default: defaultState,
});

export const useApp = () => {
    return useRecoilValue(appState);
};

export const useSetApp = () => {
    return useSetRecoilState(appState);
};

export const useResetApp = () => {
    const setApp = useSetApp();
    return () => setApp(defaultState);
};

export const useUpdateApp = () => {
    const setApp = useSetRecoilState(appState);
    const updateApp = useCallback(
        (newApp: Partial<AppState>) => setApp((app) => ({ ...app, ...newApp })),
        [setApp]
    );
    return updateApp;
};

export default appState;
