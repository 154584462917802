import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GA4React } from "ga-4-react";
import { GA4ReactResolveInterface } from "ga-4-react/dist/models/gtagModels";

declare global {
    interface Window {
        googleAnalytics: boolean;
    }
}

const GOOGLE_ANALYTICS_CODE = "G-8CK6259HKP";
const GOOGLE_ADDITIONAL_CODES = ["G-P727WV4PGX"];
const isProd = ["app.testbox.com", "keap.testbox.com"].includes(
    window.location.hostname
);
const ga4react = new GA4React(
    isProd ? GOOGLE_ANALYTICS_CODE : "",
    {
        debug_mode: !isProd,
    },
    isProd ? GOOGLE_ADDITIONAL_CODES : []
);

export const DISABLE_GOOGLE_ANALYTICS = "DISABLE_GOOGLE_ANALYTICS";
export const useGoogleAnalytics = (): GA4ReactResolveInterface | undefined => {
    const [initialized, setInitialized] = useState(false);
    const ga4init = GA4React.isInitialized();
    const gaDisabled = localStorage.getItem(DISABLE_GOOGLE_ANALYTICS);

    useEffect(() => {
        if (gaDisabled) {
            console.log("Google Analytics is disabled on this device");
        } else if (!ga4init) {
            ga4react
                .initialize()
                .then(() => setInitialized(true))
                .catch(() => {
                    console.error("Google Analytics failed to load");
                });
        }
    }, [ga4init, gaDisabled]);

    return initialized ? ga4react : undefined;
};

export function useGoogleAnalyticsRouteListener() {
    const history = useHistory();
    const googleAnalytics = useGoogleAnalytics();
    useEffect(() => {
        const unlisten = history.listen((location) => {
            if (googleAnalytics) {
                googleAnalytics.pageview(location.pathname + location.search);
            }
        });
        return unlisten;
    }, [history, googleAnalytics]);
}
