import axios from "axios";

export const LONG_POLL = 60 * 1000;
export const SHORT_POLL = 2 * 1000;

const axiosClient = axios.create({
    baseURL: "/api/",
    xsrfHeaderName: "X-CSRFToken",
    xsrfCookieName: "csrftoken",
});
axiosClient.interceptors.request.use((config) => {
    // ensure our api calls are made with trailing slash
    const href_and_params = config?.url?.split("?");
    const href = href_and_params?.[0] || "";
    const get_params = href_and_params?.[1] || "";
    if (!href.endsWith("/")) {
        config.url = href + "/";
        if (get_params) {
            config.url += "?" + get_params;
        }
    }
    return config;
});

export default axiosClient;
